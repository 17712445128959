import showMessage from "../common/showMessage";

const handleRequestFail = (contactForm) => (responseBody) => {
  contactForm.querySelector("button[type='submit']").classList.remove("loading");
  contactForm.querySelector("button[type='submit']").removeAttribute("disabled");

  if (responseBody === "email is not valid") {
    showMessage.error(
      document.getElementById("contact-email-input"),
      "メールアドレスが不正です。打ち間違えてないかどうかご確認していただくか、別のアドレスをご入力ください。"
    );
  } else {
    showMessage.error(contactForm, "フォーム送信中にエラーが発生しました。<br>再度お試しください。");
  }
};
export default handleRequestFail;
