import handleContactSubmit from "./contact/handleSubmit";

window.addEventListener("load", function () {
  const $ = window.$;
  $(".titleWrapper").click(function () {
    const toggle = $(this).next("div#descwrapper");
    $(toggle).slideToggle("slow");
  });
  $(".inactive").click(function () {
    $(this).toggleClass("inactive active");
  });
  $(".active").click(function () {
    $(this).toggleClass("inactive active");
  });

  window.onscroll = function () {
    myFunction();
  };

  const header = document.getElementById("myheader");
  const sticky = 80;

  function myFunction() {
    if (window.pageYOffset > sticky) {
      header.classList.add("sticky");
      header.classList.remove("shadow");
    } else {
      header.classList.remove("sticky");
      header.classList.add("shadow");
    }
  }

  $(".menu-toggle, .nav-m-a").on("click", function () {
    $(".menu-toggle").toggleClass("on");
    $("#nav-overlay").toggleClass("on");
    $("#nav-ul").toggleClass("on");
    $("nav ul").toggleClass("hidden");
    $("nav #nav-overlay").toggleClass("hidden");
    $(".logo-area").toggleClass("on");
    if ($("#nav-overlay").hasClass("hidden")) {
      setTimeout(() => {
        $("nav #nav-overlay").addClass("d-none");
      }, 250);
    } else {
      $("nav #nav-overlay").removeClass("d-none");
    }
  });

  // 問い合わせフォームの送信時のハンドラ設定
  const contactForm = document.getElementById("contact-form");
  if (contactForm) {
    contactForm.addEventListener("submit", (e) => {
      handleContactSubmit(contactForm, e);
    });
  }
});
