const xhrService = (xhr, handleRequestSuccess, handleRequestFail, method, url, headerList, data) => {
  // 通信時のハンドラの設定
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        handleRequestSuccess();
      } else {
        handleRequestFail(xhr.response);
      }
    }
  };

  // methodとurlを設定
  xhr.open(method, url);

  // headerの設定
  headerList.forEach((header) => {
    xhr.setRequestHeader(header.key, header.value);
  });

  // 送信
  data ? xhr.send(JSON.stringify(data)) : xhr.send();
};

export default xhrService;
