import fade from "./fade";

export default {
  thankyou: (formDom, message) => {
    const height = formDom.clientHeight;
    formDom.style.height = height;
    fade.out(formDom).then(() => {
      while (formDom.firstChild) {
        formDom.firstChild.remove();
      }

      const div = document.createElement("div");
      div.innerHTML = message;
      div.style.opacity = 0;
      div.className = "thankyou-message";
      formDom.parentNode.appendChild(div);
      fade.in(div);
    });
  },

  error: (formDom, message) => {
    const errorMessage = document.querySelector("div.error-message");
    if (errorMessage) {
      errorMessage.remove();
    }
    const div = document.createElement("div");
    div.innerHTML = message;
    div.style.opacity = 0;
    div.className = "error-message";
    formDom.parentNode.prepend(div);
    fade.in(div).then(() => {
      div.scrollIntoView({ behavior: "smooth" });
    });
  },
};
