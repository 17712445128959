export default {
  out: (el) => {
    return new Promise((resolve) => {
      setInterval(function fade() {
        if (!el.style.opacity) {
          el.style.opacity = 1;
        }
        if (el.style.opacity > 0) {
          el.style.opacity -= 0.1;
        } else {
          clearInterval(fade);
          resolve();
        }
      }, 50);
    });
  },

  in: (el, display) => {
    return new Promise((resolve) => {
      el.style.display = display || "block";
      setInterval(function fade() {
        let val = parseFloat(el.style.opacity);
        if (!((val += 0.1) > 1)) {
          el.style.opacity = val;
          requestAnimationFrame(fade);
          resolve();
        }
      }, 50);
    });
  },
};
